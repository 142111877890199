import PhotoSwipe from "photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
import "../../node_modules/photoswipe/dist/photoswipe.css";
import "../../node_modules/photoswipe/dist/default-skin/default-skin.css";
import "../css/index.scss";
import "regenerator-runtime/runtime";
import { markdown } from "markdown";
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";
import { gsap, ScrollTrigger } from "gsap/all";

let html = `<section class="home">
      <!-- MENU -->
      <a href="#menu" class="hamburger-menu show" id="hamburger-menu">
        <span class="material-icons"> menu </span>
      </a>
      <div class="menu" id="menu">
        <a href="#/">
          <span class="material-icons menu-close" id="menu-close">
            disabled_by_default
          </span>
        </a>
        <nav>
          <a href="#">Accueil</a>
          <a href="#portraits">Galeries</a>
          <div class="menu__photos">
            <a href="#portraits">Portraits</a>
            <a href="#village">Vie de village</a>
            <a href="#artisanat">De main d’homme</a>
            <a href="#foret">Au cœur de la forêt</a>
            <a href="#fleuve">Au bord du fleuve</a>
          </div>
        </nav>
      </div>
      <!-- HOME -->
      <div class="pannel">
        <div class="titles">
          <h1>
            À la découverte des <br />
            Secoya d'Amazonie
          </h1>
          <h2>
            <span class="black-bg">De l'ethnologie à l'art,</span>
            <br class="hide-responsive" />
            <span class="black-bg">les photographies d'un artiste méconnu</span>
          </h2>
        </div>
        <img
          src="https://secoya-amazonie.s3.eu-west-3.amazonaws.com/hero_01_dcd24c6f08.jpg"
          alt=""
          class="show-responsive hero"
        />
      </div>
    </section>
    <section class="portraits section-container invert" id="portraits"></section>
    <section class="village section-container" id="village"></section>
    <section class="art section-container invert" id="artisanat"></section>
    <section class="foret section-container" id="foret"></section>
    <section class="eau section-container invert" id="fleuve"></section>
    <footer>
      <div class="credits">
        <h2>Crédits</h2>
        <p id="credits"></p>
      </div>
      <div class="contact">
        <h2>Contact</h2>
        <p id="contact"></p>
      </div>
      <!-- <div class="partners">
        <h2>Partenaires</h2>
        <div class="partners-container" id="partners"></div>
      </div>
      <div class="press">
        <h2>Presse</h2>
        <div class="press-container" id="press"></div>
      </div> -->
    </footer>
    <!-- PhotoSwipe -->
    <!-- Root element of PhotoSwipe. Must have class pswp. -->
    <div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
      <!-- Background of PhotoSwipe. 
       It's a separate element as animating opacity is faster than rgba(). -->
      <div class="pswp__bg"></div>

      <!-- Slides wrapper with overflow:hidden. -->
      <div class="pswp__scroll-wrap">
        <!-- Container that holds slides. 
          PhotoSwipe keeps only 3 of them in the DOM to save memory.
          Don't modify these 3 pswp__item elements, data is added later on. -->
        <div class="pswp__container">
          <div class="pswp__item"></div>
          <div class="pswp__item"></div>
          <div class="pswp__item"></div>
        </div>

        <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
        <div class="pswp__ui pswp__ui--hidden">
          <div class="pswp__top-bar">
            <!--  Controls are self-explanatory. Order can be changed. -->

            <div class="pswp__counter"></div>

            <button
              class="pswp__button pswp__button--close"
              title="Close (Esc)"
            ></button>

            <button
              class="pswp__button pswp__button--fs"
              title="Toggle fullscreen"
              id="fullscreen"
            ></button>

            <button
              class="pswp__button pswp__button--zoom"
              title="Zoom in/out"
            ></button>

            <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
            <!-- element will get class pswp__preloader--active when preloader is running -->
            <div class="pswp__preloader">
              <div class="pswp__preloader__icn">
                <div class="pswp__preloader__cut">
                  <div class="pswp__preloader__donut"></div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap"
          >
            <div class="pswp__share-tooltip"></div>
          </div>

          <button
            class="pswp__button pswp__button--arrow--left"
            title="Previous (arrow left)"
          ></button>

          <button
            class="pswp__button pswp__button--arrow--right"
            title="Next (arrow right)"
          ></button>

          <div class="pswp__caption" id="caption">
            <div class="pswp__caption__center"></div>
          </div>
        </div>
      </div>
    </div>`;

document.querySelector("body").innerHTML = html;

/* function allowAccess() {
  

  if (localStorage.getItem("mdp") !== "Amazonia") {
    let userPassword = prompt("Mot de passe");
    if (userPassword === "Amazonia") {
      document.querySelector("body").innerHTML = html;
      localStorage.setItem("mdp", "Amazonia");
    } else {
      document.querySelector("body").innerHTML = "";
      window.location = "https://www.google.fr";
    }
  } else {
    document.querySelector("body").innerHTML = html;
  }
}

allowAccess(); */

/* is loaded */
function goToHash() {
  let path = window.location.hash.substring(1);
  // Select the node that will be observed for mutations
  if (path != "") {
    const targetNode = document.getElementById(path) || document.body;

    // Options for the observer (which mutations to observe)
    const config = { attributes: true, childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    const callback = function (mutationsList, observer) {
      // Use traditional 'for loops' for IE 11
      window.location = `#${path}`;
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
  }
}

goToHash();

/* utilities */
function search(nameKey, myArray) {
  for (var i = 0; i < myArray.length; i++) {
    if (myArray[i].url === nameKey) {
      return myArray[i];
    }
  }
}

/* photoswipe */
var pswpElement = document.querySelectorAll(".pswp")[0];

function psInit(id, section, selector) {
  let pictures = [];
  section.cover.map((picture) => {
    pictures.push({
      src:
        picture.formats.large?.url ||
        picture.formats.medium?.url ||
        picture.formats.small?.url ||
        picture.formats.thumbnail.url,
      w:
        picture.formats.large?.width ||
        picture.formats.medium?.width ||
        picture.formats.small?.width ||
        picture.formats.thumbnail.width,
      h:
        picture.formats.large?.height ||
        picture.formats.medium?.height ||
        picture.formats.small?.height ||
        picture.formats.thumbnail.height,
      title: picture.caption,
    });
  });
  selector.pictures = document.querySelectorAll(`#${id} img`);
  selector.pictures.forEach((el) => {
    el.addEventListener("click", (ev) => {
      ev.preventDefault();
      galleryEvent(el, pictures);
    });
  });
}

function galleryEvent(el, pictures) {
  var options = {
    index: parseInt(el.id.split("-")[1]) - 1,
  };
  var gallery = new PhotoSwipe(
    pswpElement,
    PhotoSwipeUI_Default,
    pictures,
    options
  );
  gallery.init();

  /* hideCaptionsOnFullscreen(gallery); */
}

function hideCaptionsOnFullscreen(gallery) {
  window.addEventListener("fullscreenchange", () => {
    // set bottom gap to 0 (default is 'auto')
    gallery.options.barsSize.bottom = 44;
    // add class to root element of PhotoSwipe
    gallery.framework.addClass(gallery.template, "pswp__hidden-caption");
    // force size update
    gallery.updateSize(true);
    window.addEventListener("fullscreenchange", () => {
      gallery.options.barsSize.bottom = "auto";
      gallery.framework.removeClass(gallery.template, "pswp__hidden-caption");
      gallery.updateSize(true);
      hideCaptionsOnFullscreen(gallery);
    });
  });
}

async function fetchArticlesCards() {
  const url = "https://secoya-back3.herokuapp.com/articles";
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  let articles = await response.json();
  await articles.reverse();
  let articlesHTML = "";
  articles.map((article, i) => {
    if (i < 3 || window.innerWidth > 960)
      if (i < 6) {
        articlesHTML += `<div class="card"><a href="blog.html#${article.url}">
            <img src="${
              article.cover.formats.small?.url ||
              article.cover.formats.thumbnail.url
            }" alt="">
            <h3>${article.title}</h3>
            <p>${article.head}</p>
            <a href="blog.html#${article.url}" class="btn">Lire la suite</a>
          </a></div>`;
      }
  });
  $cards.innerHTML = articlesHTML;
}

/* masonry */
function masonryInit(id) {
  let grid = document.querySelector(`#masonry-${id}`);
  let msnry;

  imagesLoaded(grid).on("progress", function () {
    // layout Masonry after each image loads
    msnry = new Masonry(grid, {
      itemSelector: ".grid-item",
      columnWidth: ".grid-sizer",
      percentPosition: true,
    });
    msnry.layout();
  });
}

async function fetchGalerie(id) {
  const response = await fetch(`https://secoya-back3.herokuapp.com/galeries`);
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const galeries = await response.json();
  const galerie = search(id, galeries);
  return galerie;
}

async function renderSection(id, selector, invert = false) {
  const section = await fetchGalerie(id);

  let sectionHTML = "";
  let titleHTML = `<h2>${section.title}</h2>`;
  let textHTML = markdown.toHTML(section.text);
  let buttonHTML =
    section.button !== null
      ? [
          `<a href="galerie.html#${id}" class="btn shine show-responsive"
            ><span class="material-icons">
photo_camera
</span> ${section.button}</a
          >`,
          `<a href="galerie.html#${id}" class="btn shine hide-responsive"
            ><span class="material-icons">
photo_camera
</span> ${section.button}</a
          >`,
        ]
      : ["", ""];
  let coverHTML = "";
  let pictureHTML = "";
  let mapHTML = "";
  let coverArr = section.cover.sort((a, b) =>
    a.name > b.name ? 1 : b.name > a.name ? -1 : 0
  );

  if (id === "secoya") {
    buttonHTML = [
      `<p class="show-responsive p-responsive">En savoir plus sur leurs sites officiels :</p><a href="https://www.alianzaceibo.org/alianza/siekopai/" class="btn show-responsive" target="_blank"
            >Alianza Ceibo</a
          ><a href="http://www.amazonfrontlines.org/" class="btn show-responsive" target="_blank"
            >Amazon Frontlines</a
          >`,
      `<p class="hide-responsive">En savoir plus sur leurs sites officiels :</p><a href="https://www.alianzaceibo.org/alianza/siekopai/" class="btn hide-responsive" target="_blank"
            >Alianza Ceibo</a
          ><a href="http://www.amazonfrontlines.org/" class="btn hide-responsive" target="_blank"
            >Amazon Frontlines</a
          >`,
    ];

    mapHTML = `<h3>Où vivent les Secoya ?</h3><img src="https://voyage-tribu-amazonie.s3.eu-west-3.amazonaws.com/mapa-siekopai.png" alt="Territoire Secoya" class="map">`;
  }

  coverArr.map((picture, i) => {
    if (i > 0) {
      pictureHTML += `<div class="grid-item">
        <img
          src="${picture.formats.thumbnail.url}"
          alt=""
          id="${id}-${parseInt(i) + 1}"
          class="lil-pics"
        />
      </div>`;
    }
  });
  coverHTML += `<div class="grid-sizer"></div>
      <div class="gutter-sizer"></div>
      <div class="grid-item cover">
        <img
          src="${
            coverArr[0].formats.medium?.url ||
            coverArr[0].formats.small?.url ||
            coverArr[0].formats.thumbnail.url
          }"
          alt=""
          id="${id}-01"
        />
      </div>
      ${pictureHTML}`;

  if (!invert) {
    sectionHTML = `${titleHTML}
    <div class="container">
      <div class="main">
          <div class="grid" id="masonry-${id}">${coverHTML}</div>
          ${mapHTML}
          ${buttonHTML[0]}
        </div>
        <div class="pannel">
          <div class="text">
          ${textHTML}
          ${buttonHTML[1]}
          </div>
        </div>
      </div>`;
  } else if (invert) {
    sectionHTML = `${titleHTML}
    <div class="container">
      <div class="pannel">
            <div class="text">
            ${textHTML}
            ${buttonHTML[1]}
            </div>
        </div>
        <div class="main">
          <div class="grid" id="masonry-${id}">${coverHTML}</div>
          ${buttonHTML[0]}
        </div>
      </div>`;
  }
  selector.section.innerHTML = sectionHTML;

  masonryInit(id);
  psInit(id, section, selector);
  return true;
}

/* render qui */
async function renderQui() {
  let section = await fetchGalerie("qui");

  let titleHTML = `<h2>${section.title}</h2>`;
  let textHTML = markdown.toHTML(section.text);
  let mainHTML = `<div class="profile-pics">
            <div class="profile-pic">
              <img
                src="https://secoya-amazonie.s3.eu-west-3.amazonaws.com/small_profile_01_81ea9c8d11.jpg"
                alt="Jacques Desplats"
              />
              <p class="subtitle"><span class="bold">Jacques Desplats</span></p>
              <p class="subtitle">Photographe</p>
            </div>
            <div class="profile-pic">
              <img
                src="https://secoya-amazonie.s3.eu-west-3.amazonaws.com/small_profile_02_99156cd250.jpg"
                alt="Jürg Gasché"
              />
              <p class="subtitle"><span class="bold">Jürg Gasché</span></p>
              <p class="subtitle">Ethnologue</p>
            </div>
          </div>
          <div class="grid" id="masonry-qui">
          <div class="grid-sizer"></div>
      <div class="gutter-sizer"></div>
      <div class="grid-item cover">
        <img
          src="${
            section.cover[0].formats.small?.url ||
            section.cover[0].formats.thumbnail.url
          }"
          alt=""
          id="qui-01"
        />
      </div>
      </div>`;
  let sectionHTML = `${titleHTML}
  <div class="container">
        <div class="pannel">
          <div class="text">
          ${textHTML}
          </div>
          </div>
          <div class="main">
          ${mainHTML}
          </div>`;
  $qui.section.innerHTML = sectionHTML;

  $qui.pictures = document.getElementById("qui-01");
  $qui.pictures.addEventListener("click", (ev) => {
    ev.preventDefault();
    galleryEvent($qui.pictures, [
      {
        src: "https://secoya-amazonie.s3.eu-west-3.amazonaws.com/large_qui_01_e08f62beec.jpg",
        w: 2256,
        h: 1944,
      },
    ]);
  });
}

/* render minga */
async function renderMinga() {
  let section = await fetchGalerie("minga");

  let sectionHTML = "";
  let titleHTML = `<h2>${section.title}</h2>`;
  let textHTML = markdown.toHTML(section.text);

  sectionHTML = `${titleHTML}
    <div class="container">
      <div class="main">
        <div class="grid-item cover">
          <img src="${
            section.cover[0].formats.small?.url ||
            section.cover[0].formats.thumbnail.url
          }" class="minga-cover">
        </div>
      </div>
      <div class="pannel">
        <div class="text">
        ${textHTML}
        <a href="https://secoya-amazonie.s3.eu-west-3.amazonaws.com/Plaquette-pr%C3%A9sentation-association.pdf" class="btn" target="_blank"><span class="material-icons">
file_download
</span> Présentation de l'association</a>
        </div>
      </div>
    </div>`;

  document.querySelector("#minga").innerHTML = sectionHTML;
}

renderMinga();

async function renderFooter() {
  const response = await fetch(`https://secoya-back3.herokuapp.com/footer`);
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const json = await response.json();

  const $credits = document.getElementById("credits");
  const $contact = document.getElementById("contact");
  const $partners = document.getElementById("partners");
  const $press = document.getElementById("press");

  $credits.innerHTML = json.credits;
  $contact.innerHTML =
    json.contact +
    `<a href="https://esprit-minga.fr"><img src="https://secoya-amazonie.s3.eu-west-3.amazonaws.com/EM_logo_opti_2048x1329_56bb1c6d15.png" alt="Esprit Minga" class="logo-em"></a>`;

  /* $partners.innerHTML = "";
  json.partners.map((partner) => {
    $partners.innerHTML += `<div class="partner">
      <a href="${partner.link}">
        <img
          src="${partner.logo.formats.thumbnail.url}"
          alt="${partner.name}"
        />
        ${partner.name}
      </a>
    </div>`;
  });

  $press.innerHTML = "";
  json.press.map((pres) => {
    $press.innerHTML += `<div class="press-item">
      <a href="${pres.link}">
        <img
          src="${pres.logo.formats.thumbnail.url}"
          alt="${pres.name}"
        />
        ${pres.name}
      </a>
    </div>`;
  }); */
}

/* dom */
const $cards = document.getElementById("cards");

/* sections */
let $secoya = {
  section: document.getElementById("secoya"),
  pictures: document.querySelectorAll("#secoya img"),
};
let $portraits = {
  section: document.getElementById("portraits"),
  pictures: document.querySelectorAll("#portraits img"),
};
let $village = {
  section: document.getElementById("village"),
  pictures: document.querySelectorAll("#village img"),
};
let $artisanat = {
  section: document.getElementById("artisanat"),
  pictures: document.querySelectorAll("#artisanat img"),
};
let $foret = {
  section: document.getElementById("foret"),
  pictures: document.querySelectorAll("#foret img"),
};
let $fleuve = {
  section: document.getElementById("fleuve"),
  pictures: document.querySelectorAll("#fleuve img"),
};
let $qui = {
  section: document.getElementById("qui"),
  pictures: document.getElementById("qui-01"),
};

renderSection("secoya", $secoya);
renderQui();
renderSection("portraits", $portraits, true);
renderSection("village", $village);
renderSection("artisanat", $artisanat, true);
renderSection("foret", $foret);
renderSection("fleuve", $fleuve, true);

/* fetch articles */
fetchArticlesCards();

renderFooter();

/* right click disable */
document.addEventListener("contextmenu", (event) => event.preventDefault());

/* END */
